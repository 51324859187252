import React from 'react';
import Slider from 'react-slick';
import { partnerSliderOne } from '../../../partials/sliderProps';
import hospitals from '../../global/hospitals';
import Table from './Table';
// import ImgNextGen from '../../../partials/imgNextGen';







import { Link, NavLink, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
// import { plans } from "./Plans.js";
// import { useSelector } from 'react-redux'






const PlanDetails = () => {

  // const { planBenefits: initialPlanBenefits } = useParams();



  return (
      <>
      <section
          className="provider-banner bg_cover p-r z-1 mb-2"
          style={{ backgroundImage: `url(assets/images/bg/providers.jpg)`, height:'30vh'}}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="page-banner-content text-center">
                  <h2 className="text-light">Plan Benefits</h2>
                  <p className='text-light'> List of your health plan benefits provided for your convenience. Consult your policy for additional information</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
        <div className="container mt-5">
        <div className="row">
          <div className="col-lg-3">
            <Card>
              <Card.Header as="h5">POLICY DETAILS FOR</Card.Header>
              <Card.Body>
                GOLD PLAN
              {/* <select
                className="form-select"
                value={chosenPlan ? chosenPlan.plan.toLowerCase() : ""}
                onChange={(e) => handlePlanChange(e.target.value)}
              >
                <option value="" disabled>
                  Select a Plan
                </option>
                {userLocation === "NG" ? 
                  plans.map((plan) => (
                    <option key={plan.plan} value={plan.plan.toLowerCase()}>
                      {plan.plan.toUpperCase()}  ₦{plan.amount}
                    </option>
                  )) : 
                  plans2.map((plan) => (
                    <option key={plan.plan} value={plan.plan.toLowerCase()}>
                      {plan.plan.toUpperCase()} USD{plan.amount}
                    </option>
                  ))
               }
              </select> */}
            </Card.Body>
              
              </Card>
          </div>
          <div className="col-lg-9">

          <Table/>
            {/* {selectedForm === 1 && <Contact amount={chosenPlan ? chosenPlan.amount : 0} />}
            {selectedForm === 2 && <Sponsor amount={chosenPlan ? chosenPlan.amount : 0} />}
            {selectedForm === 3 && <Family amount={chosenPlan ? chosenPlan.amount : 0} />} */}
          </div>
        </div>
      

          {/*  */}
          </div>
        </section>
        

        {/*====== End Fact Section ======*/}
        <section className="newsletter-section pt-50 pb-30"
        style={{backgroundImage:'url(assets/images/bg/wcu.jpg)'}}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-12">
              {/*=== Common Heading ===*/}
              <div className="section-title wow fadeInLeft">
                <h4>10,000+ Partner</h4>
                <h2 style={{color:'#db812e'}}>Hospitals Nationwide</h2>
              </div>
            </div>
            <div className="col-xl-8 col-lg-12">
              <Slider {...partnerSliderOne} className='mt-4'>
                  {hospitals.map((hospital)=>(
                      <div className="partner-item ms-2" key={hospital.id}>
                          <div className="partner-img">
                          {/* <ImgNextGen 
                                    srcWebp={`${hospital.image2}`} 
                                    fallback={`${hospital.image}`}
                                    alt={`${hospital.hospital}`}
                                    />   */}
                              <img src={`${hospital.image}`} alt={`${hospital.hospital}`} />
                          </div>
                      </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      </>
    );
}

export default PlanDetails